:root {
  --bs-font-sans-serif: "Lato", sans-serif;
}

.flex-1 {
  flex: 1 1 0% !important;
  min-height: 0;
  min-width: 0;
}

.flex-0 {
  flex: 0 0 auto !important;
  min-height: 0;
  min-width: 0;
}

.pointer {
  cursor: pointer;
}

.max-h-100 {
  max-height: 100%;
}

.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}

.gdi-breadcrumb,
.gdi-breadcrumb:hover,
.gdi-breadcrumb:visited,
.gdi-breadcrumb:focus {
  color: white;
}

a {
  text-decoration: none;
}

.border-dedede {
  border-color: #dedede;
}

.bg-blue-sky {
  background-color: #f1f8ff;
}

.bg-blue-light {
  background-color: #d3e9ff;
}

.bg-blue {
  background-color: #5f8ab5;
}

.bg-dark-blue {
  background-color: #314a63;
}

.text-dark-blue {
  color: #314a63;
}

.title-brand {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.bg-gray-100 {
  background-color: var(--bs-gray-100);
}
.bg-gray-200 {
  background-color: var(--bs-gray-200);
}
.bg-gray-300 {
  background-color: var(--bs-gray-300);
}
.bg-gray-400 {
  background-color: var(--bs-gray-400);
}
.bg-gray-500 {
  background-color: var(--bs-gray-500);
}
.bg-gray-600 {
  background-color: var(--bs-gray-600);
}
.bg-gray-700 {
  background-color: var(--bs-gray-700);
}
.bg-gray-800 {
  background-color: var(--bs-gray-800);
}
.bg-gray-900 {
  background-color: var(--bs-gray-900);
}

.button-no-outline,
.button-no-outline:hover,
.button-no-outline:active,
.button-no-outline:focus,
.button-no-outline:focus-within {
  outline: none;
  box-shadow: none;
}

.bg-card-dark {
  background-color: #343a40;
  color: white;
}

.card {
  border-color: #f1f8ff;
}

.bg-card-header-sky-blue {
  background-color: #f1f8ff;
  border: none;
  color: black;
}

.title-page {
  font-size: 30px;
}

.input-error {
  border: 1px solid red;
}

.error-server {
  position: fixed;
  bottom: 0px;
  width: 100%;
  right: 0;
}

.title-login {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  top: 24px;
  left: 24px;
}

.block-griglia {
  background-color: #f8f9fa;
  width: 102px;
  height: 102px;
  margin-right: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-archivio {
  object-fit: contain;
  background-color: #000;
}

.img-archivio-hover{
  opacity: 0.2;
  object-fit: contain;
  background-color: #000;
  border: 1px solid rgba(13, 110, 253, 0.5);
}

.cell-container{
  width: 102px;
  height: 102px;
  position: relative;
}